import React from "react";
import DownlineMembersTable from "../../components/Tree/DownlineCustomersTable";
import { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";

const DownlineAdvertisers = () => {
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <>
            <TreeViewNavbar menu={"downlineAdvertisers"} />
            <DownlineMembersTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                userType={"advertiser"}
            />
        </>
    );
};

export default DownlineAdvertisers;
