import axios from "axios";
import API from "../../api/api";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const AdvertService = {
    getAdvertsTiles: async (currentLang) => {
        return callApi(`advert-tiles?langCode=${currentLang}`);
    },
    getAdverts: async (
        page,
        limit,
        category,
        subCategory,
        country,
        status,
        currentLang
    ) => {
        return callApi(
            `adverts-statement?start=${page}&limit=${limit}&category=${category}&subcategory=${subCategory}&country=${country}&status=${status}&langCode=${currentLang}`
        );
    },
    addAdvert: async (data) => {
        const customAxios = axios.create({
            baseURL: BASE_URL,
        });

        // Copy the api-key and access-token headers from the API instance to customAxios
        customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
        customAxios.defaults.headers.common["access-token"] =
            localStorage.getItem("access-token") || "";

        // Set the "Content-Type" header to "multipart/form-data"
        customAxios.defaults.headers.common["Content-Type"] =
            "multipart/form-data";

        try {
            const response = await customAxios.post(`add-adverts`, data);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    },

    editAdvert: async (data) => {
        const customAxios = axios.create({
            baseURL: BASE_URL,
        });

        // Copy the api-key and access-token headers from the API instance to customAxios
        customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
        customAxios.defaults.headers.common["access-token"] =
            localStorage.getItem("access-token") || "";

        // Set the "Content-Type" header to "multipart/form-data"
        customAxios.defaults.headers.common["Content-Type"] =
            "multipart/form-data";

        try {
            const response = await customAxios.post(`update-advert-data`, data);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    },
    deleteAdvert: async (data) => {
        return API.post("delete-advert-api", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    getAdvertsData: async (advertId) => {
        return callApi(`advert-data/${advertId}`);
    },
};
export default AdvertService;
