import { Chart } from "chart.js";
import React, { useEffect, useRef } from "react";
import { Pie } from "react-chartjs-2";
import { createChart } from "../../utils/pieChart";

const PieChart = ({ chartData }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        // if (charts !== null) {
        const cleanup = createChart(chartRef, chartData);
        return () => {
            cleanup();
        };
        // }
    }, [chartData]);
    return (
        <div className="col-md-5">
            <div
                style={{
                    width: "100%",
                    height: "95%",
                    float: "left",
                    background: "#ffffff",
                    mixBlendMode: "normal",
                    boxShadow: "0px 3px 7px rgba(149, 149, 149, 0.027)",
                    borderRadius: "7px",
                    padding: "15px",
                    marginBottom: "7px",
                    minHeight: "330px",
                }}
            >
                <div
                    style={{
                        width: "50%",
                        height: "auto",
                        position: "relative",
                        margin: "auto",
                    }}
                >
                    <h5>Joining Chart</h5>
                    <div className="joinings_viewBox_graph">
                        <canvas
                            ref={chartRef}
                            data={chartData}
                            id="joiningChart"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PieChart;
