export const setLoginResponseCheck = () => {
    const accessToken = localStorage.getItem("access-token");
    const user = localStorage.getItem("user");
    const defaultCurrency = localStorage.getItem("defaultCurrency");
    const defaultLanguage = localStorage.getItem("defaultLanguage");
    const userType = localStorage.getItem("userType");
    if (!accessToken || !user || !defaultCurrency || !defaultLanguage) {
        localStorage.clear();
    }
    const data = {
        accessToken,
        user,
        defaultCurrency,
        defaultLanguage,
        userType,
    };

    return data;
};
