// const BASE_URL = "http://192.168.9.7:5001/";
// const BASE_URL = "http://162.19.146.134:1618/";
const BASE_URL = "http://193.108.52.215:5000/";

// const BASE_URL = "http://44.195.135.3:3500/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const PAYPAL_CLIENT_ID =
    "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

const STRIPE_CLIENT_KEY =
    "pk_test_51LHkzeKD7ZhA819wxNlZjA7rJtL4QnhqxPdo0BX5QF7L2QxN9Z8X0eQi3YTuWMs5izAI02xEk88TPaMx1bHUMVqc00CDofyNSN";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID, STRIPE_CLIENT_KEY };
