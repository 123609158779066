import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Ls } from "dayjs";
import { Modal } from "antd";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "./ImageCarousel";
const CardComponent = (props) => {
    const { t } = useTranslation();
    const DeleteAdvertMutation = ApiHook.CallDeleteAdvert();
    const [cardHoverStatus, setCardHoverStatus] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const wordTrim = (content) => {
        if (content.length > 100) {
            content = content.substring(0, 50).trim() + "...";
        }
        return content;
    };
    const cardClick = () => {
        // props.setShow(true);
        props.setPreviewCallStatus(true);
        props.setCurrentAdvert(props.advertId);
    };
    const handleHover = () => {
        setCardHoverStatus(true);
    };
    const handleHoverRemoval = () => {
        setCardHoverStatus(false);
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        props.setAdvertDataEdit(props.advertId);
        // props.setShowEditAdvert(true);
        props.setEditCallStatus(true);
    };
    const handleClickDelete = (e) => {
        e.stopPropagation();
        setIsConfirmationOpen(true);
    };

    const handleConfirm = async () => {
        await DeleteAdvertMutation.mutateAsync(
            { advertId: props.advertId },
            {
                onSuccess: () => {
                    setIsConfirmationOpen(false);
                    props.setApiChange(true);
                    toast.success(t("advert_delete_successful"));
                },
            }
        );
        setIsConfirmationOpen(false);
    };

    const handleCancel = () => {
        setIsConfirmationOpen(false);
    };
    return (
        <>
            <div
                className="advert-cont"
                onMouseEnter={() => {
                    handleHover();
                }}
                onMouseLeave={() => {
                    handleHoverRemoval();
                }}
            >
                <div
                    className="advert-img-cont"
                    onClick={() => {
                        handleHover();
                    }}
                >
                    <ImageWithFallback
                        src={props.image}
                        alt={"error"}
                        instyle={{
                            filter: cardHoverStatus ? "blur(2px)" : "none",
                        }}
                    />
                    {(cardHoverStatus || true) && (
                        <div className="advert-cont-head">
                            <div>
                                <button
                                    className="btn btn-primary advert-content-button"
                                    onClick={cardClick}
                                >
                                    <i className="fa-solid fa-eye"></i>
                                </button>
                                <button
                                    className="btn btn-primary advert-content-button"
                                    onClick={(e) => {
                                        handleClickDelete(e);
                                    }}
                                >
                                    <i className="fa-solid fa-trash-can"></i>
                                </button>
                                <button
                                    className="btn btn-primary advert-content-button"
                                    onClick={(e) => {
                                        handleEditClick(e);
                                    }}
                                >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="advert-data-cont">
                    <article className="advert-data-title">
                        {props.title}
                    </article>
                    <article className="advert-data-status">
                        {" "}
                        <span
                            style={{
                                color:
                                    props?.status == 0
                                        ? "coral"
                                        : props?.status == 1
                                        ? "Green"
                                        : "Red",
                            }}
                        >
                            {" "}
                            {props?.status == 0
                                ? "Pending"
                                : props?.status == 1
                                ? "Accepted"
                                : "Rejected"}
                        </span>
                    </article>

                    {/* <article>{wordTrim(props.description)}</article> */}
                </div>
            </div>
            <Modal
                title="Confirmation"
                open={isConfirmationOpen}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText="Confirm"
                cancelText="Cancel"
            >
                <p>Are you sure you want to proceed?</p>
            </Modal>
        </>
    );
};
export default CardComponent;
