import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";

import CardComponent from "./CardComponent";
import AdvertFilters from "./AdvertsFilter";
import AdvertShowModal from "./AdvertModal";
import { Pagination } from "antd";
import TablePagination from "../Common/table/TablePagination";

const Adverts = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [show, setShow] = useState(false);
    const [currentAdvert, setCurrentAdvert] = useState("");
    const [previewCallStatus, setPreviewCallStatus] = useState(false);

    // const [apiChange, props.setApiChange] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const { t } = useTranslation();
    const currentLang = useSelector(
        (state) => state?.user?.selectedLanguage?.code
    );
    useEffect(() => {
        setTotalPages(props.totalPages);
    }, [props.totalPages]);

    useEffect(() => {
        props.setApiChange(true);
    }, [currentLang]);
    const advertsData = ApiHook.CallGetAdverts(
        currentPage,
        props.itemsPerPage,
        selectedCategory,
        selectedSubcategory,
        selectedCountry,
        selectedStatus,
        props.apiChange,
        props.setApiChange,
        setTotalPages,
        setCurrentPage,
        currentLang
    );

    const languages = useSelector(
        (state) => state.dashboard.appLayout.languages
    );

    const previewData = ApiHook.CallGetAdvertsData(
        currentAdvert,
        previewCallStatus,
        setPreviewCallStatus,
        setShow
    );
    const handleClose = () => {
        setShow(false);
        setCurrentAdvert("");
    };
    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            props.setApiChange(true);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
        props.setApiChange(true);
    };

    const toPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            props.setApiChange(true);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(1);
        props.setApiChange(true);
    };

    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        props.setItemsPerPage(selectedValue);
        setCurrentPage(1);
        setTotalPages(
            Math.ceil(Number(props.totalAdvertsCount) / selectedValue)
        );
        props.setApiChange(true);
    };
    return (
        <div style={{ marginTop: "5px" }}>
            <AdvertFilters
                category={props?.categories ?? []}
                countries={props?.countries ?? []}
                subCategory={props?.subCategory ?? []}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                setApiChange={props.setApiChange}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                setCurrentPage={setCurrentPage}
            />
            <div className="advert_show">
                <div className="advert_card_header">
                    {advertsData?.data?.data?.adverts?.length > 0 ? (
                        advertsData?.data?.data?.adverts?.map((item, index) => (
                            <CardComponent
                                title={item?.title}
                                description={item?.description}
                                image={item?.thumbnailImage}
                                status={item?.status}
                                advertId={item.id}
                                advertData={item}
                                setCurrentAdvert={setCurrentAdvert}
                                setShow={setShow}
                                setApiChange={props.setApiChange}
                                setShowEditAdvert={props.setShowEditAdvert}
                                setAdvertDataEdit={props.setAdvertDataEdit}
                                setCurrentPage={setCurrentPage}
                                key={`cardComponent${index}`}
                                setPreviewCallStatus={setPreviewCallStatus}
                                setEditCallStatus={props.setEditCallStatus}
                            />
                        ))
                    ) : (
                        <div className="advert_no_data_found">
                            <img
                                src="images/no-ad.png"
                                style={{ width: "fit-content" }}
                                alt=""
                            />
                            <div>Sorry no adverts found</div>
                        </div>
                    )}
                </div>

                {advertsData?.data?.data?.adverts &&
                    advertsData?.data?.data?.adverts?.length !== 0 && (
                        <TablePagination
                            startPage={1}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            itemsPerPage={props.itemsPerPage}
                            setItemsPerPage={props.setItemsPerPage}
                            toNextPage={toNextPage}
                            toLastPage={toLastPage}
                            toPreviousPage={toPreviousPage}
                            toFirstPage={toFirstPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    )}
            </div>
            {previewData?.data && show && (
                <AdvertShowModal
                    show={show}
                    advertData={previewData?.data}
                    handleClose={handleClose}
                    languages={languages}
                />
            )}
        </div>
    );
};

export default Adverts;
