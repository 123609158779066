import { accordionActionsClasses } from "@mui/material";
import AdvertService from "../../services/adverts/adverts";

export const getAdvertsTiles = async (currentLang) => {
    try {
        const response = await AdvertService.getAdvertsTiles(currentLang);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getAdverts = async (
    page,
    limit,
    category,
    subCategory,
    country,
    status,
    currentLang
) => {
    try {
        const response = await AdvertService.getAdverts(
            page,
            limit,
            category,
            subCategory,
            country,
            status,
            currentLang
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const AddAdvert = async (data) => {
    const response = await AdvertService.addAdvert(data);
    return response;
};

export const EditAdvert = async (data) => {
    const response = await AdvertService.editAdvert(data);
    return response;
};

export const deleteAdvert = async (data) => {
    const response = await AdvertService.deleteAdvert(data);
    return response;
};

export const getAdvertsData = async (advertId) => {
    try {
        const response = await AdvertService.getAdvertsData(advertId);
        return response;
    } catch (error) {
        console.log(error);
    }
};
