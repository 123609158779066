import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    termsAndPolicy: { data: {} },
    companyDetails: { data: {} },
    registerLink: { data: {} },
    sponsor: { data: {} },
    languages: [],
    selectedLanguage: "en",
};

const userSlice = createSlice({
    name: "termsAndPolicy",
    initialState,
    reducers: {
        setTermsAndPolicy: (state, action) => {
            state.termsAndPolicy = {
                terms: action.payload?.terms,
                policy: action.payload?.policy,
            };
        },
        setCompanyDetails: (state, action) => {
            state.companyDetails = action.payload;
        },
        setRegisterLink: (state, action) => {
            state.registerLink = action.payload;
        },
        setSponsor: (state, action) => {
            state.sponsor = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setSelectedReplicaLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
    },
});

export const {
    setTermsAndPolicy,
    setCompanyDetails,
    setRegisterLink,
    setSponsor,
    setLanguages,
    setSelectedReplicaLanguage,
} = userSlice.actions;

export default userSlice.reducer;
