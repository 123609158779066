import React, { useState } from "react";
import SubmitButton from "../Common/buttons/SubmitButton";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import zIndex from "@mui/material/styles/zIndex";

const AdvertFilters = ({
    category,
    countries,
    subCategory,
    selectedCategory,
    setSelectedCategory,
    selectedSubcategory,
    setSelectedSubcategory,
    selectedCountry,
    setSelectedCountry,
    setApiChange,
    selectedStatus,
    setSelectedStatus,
    setCurrentPage,
}) => {
    const { t } = useTranslation();
    const categoryList = [];
    const [selectedCategoryValue, setSelectedCategoryValue] =
        useState(selectedCategory);
    const subCategoryList = [];
    const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
        useState(selectedSubcategory);
    const countryList = [];
    const [selectedCountryValue, setSelectedCountryValue] =
        useState(selectedCountry);
    const StatusList = [];
    const [selectedStatusValue, setSelectedStatusValue] =
        useState(selectedStatus);

    const listCategoryOptions = category || [];
    const listSubCategoryOptions = subCategory || [];
    const listCountryOptions = countries || [];
    const listStatusOptions = [
        {
            label: "Approved",
            value: 1,
        },
        {
            label: "Pending",
            value: 0,
        },
        {
            label: "Rejected",
            value: 2,
        },
    ];

    const handleTabChange = (e) => {
        if (e.target.id === "searchButton") {
            setCurrentPage(1);
            setApiChange(true);
        } else if (e.target.id === "resetButton") {
            setSelectedCategory("");
            setSelectedCategoryValue("");
            setSelectedSubcategory("");
            setSelectedSubCategoryValue("");
            setSelectedCountry("");
            setSelectedCountryValue("");
            setApiChange(true);
            setSelectedStatus("");
            setSelectedStatusValue("");
        }
    };

    const handleCategorySelectChange = (values) => {
        Object.entries(values).map(([key, value]) => {
            categoryList.push(value.value);
        });
        setSelectedCategory(categoryList);
        setSelectedCategoryValue(values);
    };

    const handleSubCategorySelectChange = (values) => {
        Object.entries(values).map(([key, value]) => {
            subCategoryList.push(value.value);
        });
        setSelectedSubcategory(subCategoryList);
        setSelectedSubCategoryValue(values);
    };

    const handleCountrySelectChange = (values) => {
        Object.entries(values).map(([key, value]) => {
            countryList.push(value.value);
        });
        setSelectedCountry(countryList);
        setSelectedCountryValue(values);
    };

    const handleStatusSelectChange = (values) => {
        Object.entries(values).map(([key, value]) => {
            StatusList.push(value.value);
        });
        setSelectedStatus(StatusList);
        setSelectedStatusValue(values);
    };
    return (
        <div className="filter_Section">
            <div className="row">
                <div className="col-xl-3 col-md-3">
                    <div className="right_search_div">
                        <label className="la-control">{t("category")}</label>
                        <Select
                            options={listCategoryOptions}
                            value={selectedCategoryValue}
                            onChange={handleCategorySelectChange}
                            isMulti
                        />
                    </div>
                </div>
                {/* <div key={"2"} className="col-xl-3 col-md-3">
                    <div className="right_search_div">
                        <label className="la-control">{t("subCategory")}</label>
                        <Select
                            options={listSubCategoryOptions}
                            value={selectedSubCategoryValue}
                            onChange={handleSubCategorySelectChange}
                            isMulti
                        />
                    </div>
                </div> */}
                <div className="col-xl-3 col-md-3">
                    <div className="right_search_div">
                        <label className="la-control">{t("status")}</label>
                        <Select
                            options={listStatusOptions}
                            value={selectedStatusValue}
                            onChange={handleStatusSelectChange}
                            isMulti
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="right_search_div">
                        <label className="la-control">{t("country")}</label>
                        <Select
                            options={listCountryOptions}
                            value={selectedCountryValue}
                            onChange={handleCountrySelectChange}
                            isMulti
                        />
                    </div>
                </div>

                <div className="col-xl-2 col-md-3 mt-4">
                    <div className="right_search_div d-flex gap-1">
                        <SubmitButton
                            isSubmitting=""
                            text="search"
                            className="btn btn-primary"
                            click={handleTabChange}
                            id="searchButton"
                        />
                        <SubmitButton
                            isSubmitting=""
                            text="reset"
                            className="btn btn-secondary"
                            click={handleTabChange}
                            id="resetButton"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertFilters;
