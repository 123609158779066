import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Upload } from "antd";
import ReactQuill from "react-quill";
import { json } from "react-router";

const AddAdvertModal = ({
    show,
    handleClose,
    countries,
    categories,
    setApiChangeAdvert,
    laguages,
}) => {
    const {
        register,
        setValue,
        setError,
        trigger,
        reset,
        watch,
        getValues,
        formState: { errors },
    } = useForm();
    const formValues = watch();

    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState(false);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(false);
    const [statesOption, setSatesOption] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [files, setFiles] = useState([]);
    const [thumbnailImageList, setThumbnailImageList] = useState([]);
    const [thumbnailImage, setThumbnailImages] = useState([]);
    const [addMoreImageStatus, setAddMoreImageStatus] = useState(false);
    const [currentLangCode, setCurrentLangCode] = useState("en");

    const AddAdvertMutation = ApiHook.CallAddAdvert();
    const handleCategoryChange = (e) => {
        const selectedCategoryValue = e.target.value;
        setSelectedCategory(true);
        const category = categories.find(
            (cat) => cat.value == selectedCategoryValue
        );
        setSubcategories(category?.subCategories || []);
        setValue("category", selectedCategoryValue);
    };
    const handleCountryChange = (e) => {
        const selectedCountryValue = e.target.value;
        setSelectedCountry(true);
        const counrty = countries.find(
            (cat) => cat.value == selectedCountryValue
        );
        setSatesOption(counrty?.states || []);
        setValue("countryId", selectedCountryValue);
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
                color: "black",
            }}
            type="button"
        >
            <i className="fa-solid fa-upload"></i>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleChangeFile = ({ file, fileList: newFileList }) => {
        const convertedFileList = newFileList.map((f) => {
            if (f.uid === file.uid) {
                // Convert the current file object to a File instance
                return new File([f.originFileObj], f.name, {
                    type: f.type,
                });
            }
            // Keep the existing File instance or convert to File instance if not already
            return f instanceof File
                ? f
                : new File([f.originFileObj], f.name, { type: f.type });
        });

        setFiles(convertedFileList);

        setFileList(newFileList);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const isValid = await trigger();
        for (let item of laguages) {
            if (errors[`${item?.code}`]) {
                setCurrentLangCode(item?.code);
                break;
            }
        }
        if (isValid) {
            const formData = new FormData();
            formData.append("thumbnail", thumbnailImage[0]);
            files.forEach((file) => {
                formData.append("file", file);
            });

            for (const [key, value] of Object.entries(formValues)) {
                if (key !== "file") {
                    if (typeof value == "object") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            }
            await AddAdvertMutation.mutateAsync(formData, {
                onSuccess: () => {
                    handleClose();
                },
            });
            reset();
            setFileList([]);
            setThumbnailImageList([]);
            setFiles([]);
            setThumbnailImages([]);
            setApiChangeAdvert(true);
        }
    }

    const handleChangeThumbNail = ({ file, fileList: newFileList }) => {
        const convertedFileList = newFileList.map((f) => {
            if (f.uid === file.uid) {
                // Convert the current file object to a File instance
                return new File([f.originFileObj], f.name, {
                    type: f.type,
                });
            }
            // Keep the existing File instance or convert to File instance if not already
            return f instanceof File
                ? f
                : new File([f.originFileObj], f.name, { type: f.type });
        });

        setThumbnailImages(convertedFileList);

        setThumbnailImageList(newFileList);
        setAddMoreImageStatus(true);
    };

    const modules = {
        toolbar: [
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            [{ align: [] }],
        ],
    };

    const descriptionChange = (value) => {
        setValue(`${currentLangCode}.description`, value, {
            shouldValidate: true,
        });
    };

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            id="addAdvert"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("addAdvert")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form className="advert-form">
                    <Form.Group controlId="formCategory">
                        <Form.Label>{t("category")}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register("category", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Select Category</option>
                            {categories?.map((item) => (
                                <option value={item.value} key={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.category?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {selectedCategory && (
                        <Form.Group controlId="formSubcategory">
                            <Form.Label>{t("subcategory")}</Form.Label>
                            <Form.Control
                                as="select"
                                {...register("subCategory", {
                                    required: {
                                        value: true,
                                        message: t("this_field_is_required"),
                                    },
                                })}
                                isInvalid={!!errors.subCategory}
                            >
                                <option value="">Select subCategory</option>
                                {subcategories.map((subcategory) => (
                                    <option
                                        key={subcategory.value}
                                        value={subcategory.value}
                                    >
                                        {subcategory.label}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.subCategory?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    <Form.Group controlId="formCountry">
                        <Form.Label>{t("country")}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register("countryId", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.countryId}
                            onChange={handleCountryChange}
                        >
                            <option value="">Select country</option>
                            {countries?.map((item) => (
                                <option value={item.value} key={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.countryId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {selectedCountry && (
                        <Form.Group controlId="formstate">
                            <Form.Label>{t("state")}</Form.Label>
                            <Form.Control
                                as="select"
                                {...register("state", {
                                    required: {
                                        value: false,
                                        message: t("this_field_is_required"),
                                    },
                                })}
                                isInvalid={!!errors.state}
                            >
                                <option value="">Select state</option>
                                {statesOption.map((state) => (
                                    <option
                                        key={state.value}
                                        value={state.value}
                                    >
                                        {state.label}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.state?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    <Form.Group controlId="formZipCode">
                        <Form.Label>{t("zipcode")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ZIP code"
                            {...register("zip", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.zip}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.zip?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div style={{ marginTop: "10px" }}>
                        <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                            style={{ marginLeft: "10px" }}
                        >
                            {laguages &&
                                laguages?.map((item) => (
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className={`nav-link ${
                                                currentLangCode === item.code &&
                                                "active"
                                            }`}
                                            id={`${item.code}-tab`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#${item.code}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`${item.code}`}
                                            aria-selected="true"
                                            onClick={() => {
                                                setCurrentLangCode(item?.code);
                                            }}
                                        >
                                            <img
                                                src={`/${item?.flagImage}`}
                                                alt=""
                                            />
                                        </button>
                                    </li>
                                ))}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {laguages &&
                                laguages?.map((item) => (
                                    <div
                                        className={`tab-pane fade ${
                                            currentLangCode === item.code &&
                                            "active show"
                                        } `}
                                        id={`${item.code}`}
                                        role="tabpanel"
                                        aria-labelledby={`${item.code}-tab`}
                                    >
                                        <div>
                                            <Form.Group controlId="formTitle">
                                                <Form.Label>
                                                    {t("title")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Title"
                                                    {...register(
                                                        `${item.code}.title`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.title
                                                    }
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.title?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formPrice">
                                                <Form.Label>
                                                    {t("price")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Price"
                                                    {...register(
                                                        `${item.code}.price`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.price
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.price?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formOffer">
                                                <Form.Label>
                                                    {t("offer")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Offer"
                                                    {...register(
                                                        `${item.code}.offer`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                            min: {
                                                                value: 0,
                                                                message: t(
                                                                    "value_must_be_greater_than_or_equal_to_0"
                                                                ),
                                                            },
                                                            max: {
                                                                value: 100,
                                                                message: t(
                                                                    "value_must_be_less_than_or_equal_to_100"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.offer
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.offer?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formTown">
                                                <Form.Label>
                                                    {t("town")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Town"
                                                    {...register(
                                                        `${item.code}.town`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.town
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.town?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formDescription">
                                                <Form.Label>
                                                    {t("description")}
                                                </Form.Label>
                                                <ReactQuill
                                                    value={
                                                        formValues[item?.code]
                                                            ?.description
                                                    }
                                                    onChange={descriptionChange}
                                                    modules={modules}
                                                    style={{
                                                        height: "200px",
                                                        borderRadius: "10px",
                                                    }}
                                                    maxLength={3000} // Set the maximum length to 3000 characters
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item?.code}`]
                                                            ?.description
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div
                        className="form modal-input-form "
                        style={{ marginTop: "60px" }}
                    >
                        <p style={{ marginBottom: "5px" }}>
                            {t("thumbnailImage")}
                        </p>
                        <Upload
                            listType="picture-card"
                            fileList={thumbnailImageList}
                            onChange={handleChangeThumbNail}
                            name="images"
                            customRequest={({ onSuccess }) =>
                                onSuccess("success")
                            }
                            // previewFile={false}
                            showPreviewIcon={false}
                            multiple={false}
                            showUploadList={{ showPreviewIcon: false }}
                        >
                            {thumbnailImageList.length >= 1
                                ? null
                                : uploadButton}
                        </Upload>
                    </div>

                    {addMoreImageStatus && (
                        <div
                            className="form modal-input-form"
                            style={{ marginTop: "12px" }}
                        >
                            <p style={{ marginBottom: "5px" }}>
                                {t("attachImages")}
                            </p>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onChange={handleChangeFile}
                                name="images"
                                customRequest={({ onSuccess }) =>
                                    onSuccess("success")
                                }
                                // previewFile={false}
                                showPreviewIcon={false}
                                multiple={true}
                                showUploadList={{ showPreviewIcon: false }}
                            >
                                {fileList.length >= 8 ? null : uploadButton}
                            </Upload>
                        </div>
                    )}

                    <div style={{ marginTop: "15px" }}>
                        <SubmitButton
                            isSubmitting={false}
                            click={handleSubmit}
                            text="submit"
                            className={"btn btn-primary submit_btn"}
                            // isLoading={transferMutation.isLoading}
                            isLoading={AddAdvertMutation.isLoading}
                        />
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AddAdvertModal;
