import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
const AdvertTiles = ({ tiles }) => {
    const { t } = useTranslation();

    return (
        <div className="ewallet_top_cnt_sction">
            <div className="row">
                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img
                                        src={"/images/ewallet-ico2.png"}
                                        alt=""
                                    />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("activeCount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {tiles?.activeAdsCount ?? 0}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img
                                        src={"/images/approved-ico.svg"}
                                        alt=""
                                    />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("pendingCount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {tiles?.pendingAdsCount ?? 0}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img src={"/images/paid-ico.svg"} alt="" />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("rejectedAdsCount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {tiles?.rejectedAdsCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertTiles;
