import React, { useEffect, useRef, useState } from "react"; // Import useEffect and useState
import { useNavigate, useParams } from "react-router";
import welcomeImg from "../../assests/images/welcomeImg.svg";

import LoginService from "../../services/auth/Login";
import { toast } from "react-toastify";

const VerifyEmailComponent = () => {
    const { encryptedUsername, encryptedAdminUsername } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            // Skip API call on initial render
            isInitialRender.current = false;
            return;
        }

        // API call
        LoginService.verifyEmail(encryptedUsername, encryptedAdminUsername)
            .then((res) => {
                if (res.status) {
                    toast.success(res?.data);
                    navigate("/login");
                } else {
                    // Handle other cases if needed
                }
            })
            .catch((error) => {
                console.error("Error verifying email:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [encryptedUsername, encryptedAdminUsername, navigate]);

    return (
        <>
            <section className="loginSection">
                <div className="container centerDiv">
                    <div className="loginBgImg"></div>
                    <div className="loginBg">
                        <div className="row"></div>
                        <div className="col-md-6 logincredDetail">
                            <div className="loginFormSec login_left_section">
                                <div className="loginLogo">
                                    {/* <img src={company?.logo} alt="" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="welcomeImgSec">
                                <div className="welcomHeadSec">
                                    <h2>Welcome</h2>
                                    <p>Your account has been verified</p>
                                </div>
                                <div className="welcomeImg">
                                    <img src={welcomeImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VerifyEmailComponent;
