import React, { useState } from "react";
import OptionsButton from "../../components/Common/buttons/OptionsButton";
import AdvertTiles from "../../components/Adverts/AdvertTiles";
import Adverts from "../../components/Adverts/Adverts";
import FundTransfer from "../../components/Ewallet/FundTransfer";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddAdvertModal from "../../components/Adverts/AddAdvertModal";
import EditAdvertModal from "../../components/Adverts/EditAdvertModal";

const AdvertLayout = () => {
    const [showAddAdvert, setShowAddAdvert] = useState(false);
    const [showEditAdvert, setShowEditAdvert] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [advertDataId, setAdvertData] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [apiChangeAdvert, setApiChangeAdvert] = useState(true);
    const [previewCallStatus, setPreviewCallStatus] = useState(false);

    const { t } = useTranslation();
    const style = {
        position: "absolute",
        inset: "0px auto auto 0px, margin: 0px",
        transform: "translate(190px, 42px)",
    };

    const handleAddAdvert = () => {
        setShowAddAdvert((prevshowAddAdvert) => !prevshowAddAdvert);
    };
    const handleEditAdvertClose = () => {
        setShowEditAdvert((prevshowAddAdvert) => !prevshowAddAdvert);
    };

    const currentLang = useSelector(
        (state) => state?.user?.selectedLanguage?.code
    );
    const laguages = useSelector(
        (state) => state?.dashboard?.appLayout?.languages
    );
    const adverts = ApiHook.CallGetAdvertsTiles(currentLang);

    const previewData = ApiHook.CallGetAdvertsEditData(
        advertDataId,
        previewCallStatus,
        setPreviewCallStatus,
        setShowEditAdvert
    );
    return (
        <>
            <div className="page_head_top">{t("adverts")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4 text-end">
                        <OptionsButton
                            title={"add-advert"}
                            handleOpen={handleAddAdvert}
                            style={style}
                            type={"advert"}
                        />
                    </div>
                </div>
            </div>
            <AdvertTiles tiles={adverts?.data} />
            <Adverts
                countries={adverts?.data?.countries}
                categories={adverts?.data?.categories}
                // subCategory={adverts?.data?.subCategory}
                totalAdvertsCount={adverts?.data?.totalAdsCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={Math.ceil(
                    Number(adverts?.data?.totalAdsCount ?? 0) / itemsPerPage
                )}
                apiChange={apiChangeAdvert}
                setApiChange={setApiChangeAdvert}
                setAdvertDataEdit={setAdvertData}
                setShowEditAdvert={setShowEditAdvert}
                languages={laguages}
                setEditCallStatus={setPreviewCallStatus}
            />

            <AddAdvertModal
                show={showAddAdvert}
                handleClose={handleAddAdvert}
                countries={adverts?.data?.countries}
                categories={adverts?.data?.categories}
                setApiChangeAdvert={setApiChangeAdvert}
                laguages={laguages}
            />
            {!!previewData?.data && !!showEditAdvert && (
                <EditAdvertModal
                    advertData={previewData?.data}
                    show={showEditAdvert}
                    handleClose={handleEditAdvertClose}
                    countries={adverts?.data?.countries}
                    categories={adverts?.data?.categories}
                    setApiChangeAdvert={setApiChangeAdvert}
                    laguages={laguages}
                />
            )}
        </>
    );
};

export default AdvertLayout;
