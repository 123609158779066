import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import SponserTreeContent from "../../components/Tree/sponser-tree/SponserTreeContent";
import { useState } from "react";

const SponserTree = () => {
    const [selectedUserId, setSelectedUserId] = useState("");
    const [searchUsername, setSearchUsername] = useState("");
    const [doubleClickedUser, setDoubleClickedUser] = useState("");

    return (
        <>
            <TreeViewNavbar
                menu={"sponsorTree"}
                searchUsername={searchUsername}
                setSearchUsername={setSearchUsername}
            />
            <SponserTreeContent
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                doubleClickedUser={doubleClickedUser}
                setDoubleClickedUser={setDoubleClickedUser}
                searchUsername={searchUsername}
                setSearchUsername={setSearchUsername}
            />
            {/* <div
                style={{
                    position: "absolute",
                    zIndex: "99999999",
                    backgroundColor: "red",
                    height: "100px",
                    width: "100px",
                    right: "0",
                    top: "0",
                }}
            >
                <div>
                    <div
                        style={{
                            width: "5px",
                            height: "5px",
                            background: "#da73ee",
                        }}
                    ></div>
                    <span>Advertiser</span>
                </div>
            </div> */}
        </>
    );
};

export default SponserTree;
