import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ImageCarousel from "./ImageCarousel";
import DOMPurify from "dompurify";
import i18n from "../../i18n";

const AdvertShowModal = ({ advertData, show, handleClose, languages }) => {
    const [carouselImages, setCarouselImages] = useState([]);

    const { t } = useTranslation();
    const [currentLang, setCurrentLang] = useState("en");
    const handleChange = (e) => {
        const { id, value } = e.target;
    };
    const dropdownCountryRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState({
        country: false,
    });
    const toggleDropdown = (dropdown) => {
        setDropdownOpen((prevState) => ({
            country: !prevState.country,
        }));
    };
    const [selectedLanguage, setSelectedLanguage] = useState({
        id: null,
        flagImage: "images/us.svg",
        code: null,
        name: null,
    });

    const changeLanguage = (language) => {
        const newLanguage = { langId: language?.id.toString() };
        setSelectedLanguage({
            id: language?.id,
            flagImage: language?.flagImage,
            code: language?.code,
            name: language?.name,
        });
        setDropdownOpen({ country: false });
        setCurrentLang(language?.code);
        // i18n.changeLanguage(language?.code);
    };
    useEffect(() => {
        let imageList = [];
        imageList.push({
            src: advertData?.thumbnailImage,
            alt: "Image 1",
        });

        advertData?.AdvertImages?.map((item) => {
            imageList.push({
                src: item.image,
                alt: "Image 2",
            });
        });
        setCarouselImages(imageList);
    }, [advertData]);
    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="center"
            id="advertShow"
            className="offcanvas-test"
        >
            <Offcanvas.Header
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <Offcanvas.Title>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Image
                            src={
                                advertData?.status === 0
                                    ? "images/pending.png"
                                    : advertData?.status === 1
                                    ? "images/accepted.png"
                                    : "images/rejected.png"
                            }
                            preview={false}
                            style={{
                                width: "30px",
                                height: "30px",
                            }}
                        ></Image>
                        <span style={{ marginLeft: "5px" }}>
                            {" "}
                            {advertData?.status === 0
                                ? "PENDING"
                                : advertData?.status === 1
                                ? "ACCEPTED"
                                : "REJECTED"}
                        </span>
                    </div>
                </Offcanvas.Title>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                    }}
                >
                    <div
                        className={`right_notiifcation_mail_ico top_dropdown country_dropdown ${
                            dropdownOpen.country ? "show" : ""
                        }`}
                        ref={dropdownCountryRef}
                        style={{ marginRight: "30px" }}
                    >
                        <a
                            href="#"
                            className="dropdown-toggle"
                            onClick={() => toggleDropdown("country")}
                            data-bs-toggle="dropdown"
                            aria-expanded={dropdownOpen.country}
                        >
                            <img
                                src={`/${selectedLanguage?.flagImage}`}
                                alt=""
                            />
                        </a>
                        <div
                            className={`dropdown-menu usr_prfl right-0 animation slideDownIn ${
                                dropdownOpen.country ? "show" : ""
                            }`}
                        >
                            <div className="usr_prfl_setting">
                                {t("country")}
                            </div>
                            <ul className="">
                                {languages?.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            className="dropdown-item"
                                            onClick={() => changeLanguage(item)}
                                        >
                                            <img
                                                src={`/${item?.flagImage}`}
                                                alt=""
                                            />{" "}
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={handleClose}
                    ></button>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="offcanvas-body-scrollable">
                <div className="d-flex flex-column flex-lg-row">
                    <div
                        className="flex-grow-1" //me-lg-4 mb-4 mb-lg-0
                        style={{ minWidth: "40%" }}
                    >
                        <div className="mt-3 d-flex justify-content-center">
                            <ImageCarousel images={carouselImages} />
                        </div>
                    </div>
                    <div
                        className="flex-grow-2"
                        style={{ minWidth: "calc(50% + 10px)" }}
                    >
                        <Form>
                            <div className="row">
                                <div className="col-lg-6 advert-form">
                                    <Form.Group controlId="formTitle">
                                        <Form.Label>{t("title")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                advertData?.AdvertsData[
                                                    currentLang
                                                ]?.title
                                            }
                                            placeholder="Enter title"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formCategory">
                                        <Form.Label>{t("category")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                advertData?.Category[
                                                    currentLang
                                                ]
                                            }
                                            placeholder="Enter category"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formSubCategory">
                                        <Form.Label>
                                            {t("subcategory")}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                advertData?.SubCategory[
                                                    currentLang
                                                ]
                                            }
                                            placeholder="Enter sub-category"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formOffer">
                                        <Form.Label>{t("offer")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                advertData?.AdvertsData[
                                                    currentLang
                                                ]?.offer
                                            }
                                            placeholder="0"
                                            disabled
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 advert-form">
                                    <Form.Group controlId="formCountry">
                                        <Form.Label>{t("country")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={advertData?.Country?.name}
                                            placeholder="Enter country"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formState">
                                        <Form.Label>{t("state")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={advertData?.State?.name}
                                            placeholder="Enter state"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formZipCode">
                                        <Form.Label>{t("zipcode")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={advertData?.zipcode}
                                            placeholder="Enter ZIP code"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPrice">
                                        <Form.Label>{`${t(
                                            "price"
                                        )}`}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                advertData?.AdvertsData[
                                                    currentLang
                                                ]?.price
                                            }
                                            placeholder="0"
                                            disabled
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Group controlId="town">
                                <Form.Label>{t("town")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        advertData?.AdvertsData[currentLang]
                                            ?.town
                                    }
                                    placeholder="Enter town"
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="formDescription"
                                className="advert-form"
                            >
                                <Form.Label>{t("description")}</Form.Label>
                                {/* <Form.Control
                                    as="textarea"
                                    rows={6}
                                    value={advertData?.description}
                                    placeholder="Enter description"
                                    disabled
                                /> */}
                                <div className="advert-description">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                advertData?.AdvertsData[
                                                    currentLang
                                                ]?.description
                                            ),
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AdvertShowModal;
