import { useSelector } from "react-redux";
import DownloadMaterialsComponent from "../../components/Tools/DownloadMaterialsComponent";
import { ApiHook } from "../../hooks/apiHook";

const DownloadMaterials = () => {
    const langid = useSelector((state) => state.user?.selectedLanguage?.id);
    const DownloadMaterials = ApiHook.CallGetDownloadMaterials(langid ?? 1);

    return (
        <>
            <div className="page_head_top">Download Materials</div>
            <DownloadMaterialsComponent materials={DownloadMaterials?.data} />
        </>
    );
};
export default DownloadMaterials;
