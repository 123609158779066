import Chart from "chart.js/auto";

export const createChart = (chartRef, data) => {
    const ctx = chartRef.current.getContext("2d");
    const chartContainer = chartRef.current.parentNode;
    ctx.canvas.height = 250;
    ctx.canvas.width = chartContainer.clientWidth;

    const toolTipData = data.toolTipData ? data.toolTipData : [];
    const toolTipCount = data.toolTipCount ? data.toolTipCount : 0;

    const backgroundColorList = [];
    const hoverBackgroundColorList = [];
    for (let i = 0; i < toolTipCount; i++) {
        backgroundColorList.push(createGradient(ctx, "#d6baf7", "#ae69ff"));
        hoverBackgroundColorList.push("#954cea");
    }
    for (let i = 0; i < 6 - toolTipCount; i++) {
        backgroundColorList.push(createGradient(ctx, "#fff", "#fff"));
        hoverBackgroundColorList.push("#fff");
    }
    function createGradient(ctx, color1, color2) {
        const gradient = ctx.createRadialGradient(200, 200, 40, 200, 200, 300); // Adjust the values as needed
        gradient.addColorStop(0, color1);
        gradient.addColorStop(1, color2);
        return gradient;
    }

    const options = {
        type: "pie",
        data: {
            labels: [],
            datasets: [
                {
                    data: [500, 501, 502, 503, 504, 505],
                    backgroundColor: backgroundColorList,
                    hoverBackgroundColor: hoverBackgroundColorList,
                    hoverBorderColor: "transparent",
                    borderColor: "#f3f3f3",
                },
            ],
        },
        options: {
            layout: {
                padding: 10,
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    // Disable the on-canvas tooltip
                    enabled: false,

                    external: function (context) {
                        // Tooltip Element
                        let tooltipEl =
                            document.getElementById("chartjs-tooltip");

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement("div");
                            tooltipEl.id = "chartjs-tooltip";
                            tooltipEl.innerHTML =
                                "<div  id =`toolTipRoot`><div>";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        const tooltipModel = context.tooltip;
                        // console.log(
                        //     context.tooltip,
                        //     "============================="
                        // );
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove(
                            "above",
                            "below",
                            "no-transform"
                        );
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add("no-transform");
                        }

                        function getBody(bodyItem) {
                            // console.log("************", bodyItem.lines, index);
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];

                            let bodyLines = tooltipModel.body.map(getBody);
                            let sliceIndex = Number(bodyLines[0]) - 500;
                            bodyLines[0] = toolTipData[sliceIndex];

                            let innerHtml = "";

                            bodyLines.forEach(function (body, i) {
                                innerHtml +=
                                    sliceIndex < toolTipCount
                                        ? `<div style=" border-radius: 5px; padding: 2px 8px; min-width: 100px; background-color:rgb(175 87 251); color: white ">
                                <span> ${body}<span>
                                </div>`
                                        : "";
                            });
                            innerHtml += "</tbody>";

                            let tableRoot = tooltipEl.querySelector("div");
                            tableRoot.innerHTML = innerHtml;
                        }

                        const position =
                            context.chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = "absolute";
                        tooltipEl.style.left =
                            position.left +
                            window.pageXOffset +
                            tooltipModel.caretX +
                            "px";
                        tooltipEl.style.top =
                            position.top +
                            window.pageYOffset +
                            tooltipModel.caretY +
                            "px";
                        // tooltipEl.style.font = bodyFont.string;
                        tooltipEl.style.padding =
                            tooltipModel.padding +
                            "px " +
                            tooltipModel.padding +
                            "px";
                        tooltipEl.style.pointerEvents = "none";
                    },
                },
            },
        },
    };

    const chart = new Chart(ctx, options);

    return () => {
        chart.destroy();
    };
};
