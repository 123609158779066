import { Image } from "antd";
import React, { useState, useEffect } from "react";
// import "./Carousel.css";
export const ImageWithFallback = ({ src, altText, instyle = {} }) => {
    const [imgSrc, setImgSrc] = useState(src);
    useEffect(() => {
        setImgSrc(src);
    }, [src]);
    const handleImageError = () => {
        setImgSrc("images/no-image.png");
    };

    return (
        <img
            src={imgSrc}
            alt={altText}
            onError={handleImageError}
            style={instyle}
        />
    );
};
const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % images.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval);
    }, [currentIndex, images.length]);

    const goToPrevious = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    const goToNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    return (
        <div className="advert_carousel">
            <div className="advert_carousel-inner">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`advert_slide ${
                            index === currentIndex ? "active" : ""
                        }`}
                    >
                        <ImageWithFallback
                            src={image.src}
                            altText={image.alt}
                        />
                    </div>
                ))}
            </div>
            {images?.length > 1 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <div className="advert_prev" onClick={goToPrevious}>
                        <i className="fa-solid fa-less-than"></i>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        {images?.map((item, index) => (
                            <div
                                className={`advert_worm ${
                                    index === currentIndex ? "active" : ""
                                }`}
                            ></div>
                        ))}
                    </div>
                    <div className="advert_next" onClick={goToNext}>
                        <i className="fa-solid fa-greater-than"></i>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageCarousel;
