import React, { useEffect, useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Image, Upload } from "antd";
import ReactQuill from "react-quill";

const EditAdvertModal = ({
    advertData,
    show,
    handleClose,
    countries,
    categories,
    setApiChangeAdvert,
    laguages,
}) => {
    const {
        register,
        setValue,
        setError,
        trigger,
        reset,
        watch,
        getValues,
        formState: { errors },
    } = useForm();
    const formValues = watch();

    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState(false);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(false);
    const [statesOption, setSatesOption] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [files, setFiles] = useState([]);
    const [thumbnailImageList, setThumbnailImageList] = useState([]);
    const [thumbnailImage, setThumbnailImages] = useState([]);
    const [addMoreImageStatus, setAddMoreImageStatus] = useState(false);
    const [thumbnailImageEdit, setThumbnailImageEdit] = useState(null);
    const [addMoreImagesEditList, setAddMoreImagesList] = useState([]);
    const [removedImagesList, setRemovedImagesList] = useState([]);
    const [currentLangCode, setCurrentLangCode] = useState("en");

    useEffect(() => {
        let languageData = laguages?.reduce((langList, currentValue) => {
            return {
                ...langList,
                [currentValue?.code]: {
                    title: advertData?.AdvertsData[currentValue?.code]?.title,
                    description:
                        advertData?.AdvertsData[currentValue?.code]
                            ?.description,
                    price: advertData?.AdvertsData[currentValue?.code]?.price,
                    offer: advertData?.AdvertsData[currentValue?.code]?.offer,
                    town: advertData?.AdvertsData[currentValue?.code]?.town,
                },
            };
        }, {});

        reset({
            category: advertData?.categoryId ?? "",
            subCategory: advertData?.subcategoryId ?? "",
            countryId: advertData?.countryId ?? "",
            state: advertData?.stateId ?? "",
            zip: advertData?.zipcode ?? "",
            ...languageData,
        });
        if (advertData?.categoryId) {
            setSelectedCategory(true);
            const category = categories.find(
                (cat) => cat.value == advertData?.categoryId
            );
            setSubcategories(category?.subCategories || []);
        }
        if (advertData?.countryId) {
            setSelectedCountry(true);
            const counrty = countries.find(
                (cat) => cat.value == advertData?.countryId
            );
            setSatesOption(counrty?.states || []);
        }
        setAddMoreImageStatus(true);
        setThumbnailImageEdit(advertData?.thumbnailImage);
        setAddMoreImagesList(advertData?.AdvertImages);
        setRemovedImagesList([]);
        setFileList([]);
        setFiles([]);
        setThumbnailImageList([]);
    }, [advertData]);
    const AddAdvertMutation = ApiHook.CallEditAdvert();

    const handleCategoryChange = (e) => {
        const selectedCategoryValue = e.target.value;
        setSelectedCategory(true);
        const category = categories.find(
            (cat) => cat.value == selectedCategoryValue
        );
        setSubcategories(category?.subCategories || []);
        setValue("category", selectedCategoryValue);
    };
    const handleCountryChange = (e) => {
        const selectedCountryValue = e.target.value;
        setSelectedCountry(true);
        const counrty = countries.find(
            (cat) => cat.value == selectedCountryValue
        );
        setSatesOption(counrty?.states || []);
        setValue("countryId", selectedCountryValue);
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
                color: "black",
            }}
            type="button"
        >
            <i className="fa-solid fa-upload"></i>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const deleteImage = (e, type, index) => {
        e.preventDefault();
        if (type === "thumbnail") {
            setThumbnailImageEdit(null);
        } else if (type === "addMore") {
            let newRemovedList = removedImagesList;
            newRemovedList.push(index);
            setRemovedImagesList(newRemovedList);
            setAddMoreImagesList(
                addMoreImagesEditList.filter((item) => item?.id !== index)
            );
        }
    };
    const ImageComponent = ({ src, type, index }) => (
        <div className="edit-img-component">
            <Image
                src={src}
                width={105}
                preview={false}
                height={105}
                className="edit-img-component-img"
                multiple={false}
            >
                {" "}
            </Image>
            <button
                className="edit-img-component-button"
                onClick={(e) => {
                    deleteImage(e, type, index);
                }}
            >
                <i className="fa-solid fa-trash"></i>
            </button>
        </div>
    );
    const handleChangeFile = ({ file, fileList: newFileList }) => {
        const convertedFileList = newFileList.map((f) => {
            if (f.uid === file.uid) {
                // Convert the current file object to a File instance
                return new File([f.originFileObj], f.name, {
                    type: f.type,
                });
            }
            // Keep the existing File instance or convert to File instance if not already
            return f instanceof File
                ? f
                : new File([f.originFileObj], f.name, { type: f.type });
        });

        setFiles(convertedFileList);

        setFileList(newFileList);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const isValid = await trigger();
        for (let item of laguages) {
            if (errors[`${item?.code}`]) {
                setCurrentLangCode(item?.code);
                break;
            }
        }
        if (isValid) {
            const formData = new FormData();
            if (thumbnailImage?.length > 0) {
                formData.append("thumbnail", thumbnailImage[0]);
            }
            files.forEach((file) => {
                formData.append("file", file);
            });

            for (const [key, value] of Object.entries(formValues)) {
                if (key !== "file") {
                    if (typeof value == "object") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            }
            formData.append("id", advertData?.id);
            if (removedImagesList.length > 0) {
                formData.append("removedImageList", removedImagesList);
            }

            await AddAdvertMutation.mutateAsync(formData, {
                onSuccess: () => {
                    modalhandleClose();
                },
            });
            reset();
            setFileList([]);
            setThumbnailImageList([]);
            setFiles([]);
            setThumbnailImages([]);
            setApiChangeAdvert(true);
        }
    }

    const handleChangeThumbNail = ({ file, fileList: newFileList }) => {
        const convertedFileList = newFileList.map((f) => {
            if (f.uid === file.uid) {
                // Convert the current file object to a File instance
                return new File([f.originFileObj], f.name, {
                    type: f.type,
                });
            }
            // Keep the existing File instance or convert to File instance if not already
            return f instanceof File
                ? f
                : new File([f.originFileObj], f.name, { type: f.type });
        });

        setThumbnailImages(convertedFileList);

        setThumbnailImageList(newFileList);
        setAddMoreImageStatus(true);
    };

    const modalhandleClose = () => {
        reset({ title: "" });
        handleClose();
    };

    const modules = {
        toolbar: [
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            [{ align: [] }],
        ],
    };

    const descriptionChange = (value) => {
        // setValue("description", value);
        setValue(`${currentLangCode}.description`, value, {
            shouldValidate: true,
        });
    };

    return (
        <Offcanvas
            show={show}
            onHide={modalhandleClose}
            placement="end"
            id="editAdvert"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("editAdvert")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form className="advert-form">
                    <Form.Group controlId="formCategory">
                        <Form.Label>{t("category")}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register("category", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Select Category</option>
                            {categories?.map((item) => (
                                <option value={item.value}>{item.label}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.category?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {selectedCategory && (
                        <Form.Group controlId="formSubcategory">
                            <Form.Label>{t("subcategory")}</Form.Label>
                            <Form.Control
                                as="select"
                                {...register("subCategory", {
                                    required: {
                                        value: true,
                                        message: t("this_field_is_required"),
                                    },
                                })}
                                isInvalid={!!errors.subCategory}
                            >
                                <option value="">Select subCategory</option>
                                {subcategories.map((subcategory) => (
                                    <option
                                        key={subcategory.value}
                                        value={subcategory.value}
                                    >
                                        {subcategory.label}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.subCategory?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    <Form.Group controlId="formCountry">
                        <Form.Label>{t("country")}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register("countryId", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.countryId}
                            onChange={handleCountryChange}
                        >
                            <option value="">Select country</option>
                            {countries?.map((item) => (
                                <option value={item.value}>{item.label}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.countryId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {selectedCountry && (
                        <Form.Group controlId="formstate">
                            <Form.Label>{t("state")}</Form.Label>
                            <Form.Control
                                as="select"
                                {...register("state", {
                                    required: {
                                        value: false,
                                        message: t("this_field_is_required"),
                                    },
                                })}
                                isInvalid={!!errors.state}
                            >
                                <option value="">Select state</option>
                                {statesOption.map((state) => (
                                    <option
                                        key={state.value}
                                        value={state.value}
                                    >
                                        {state.label}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.state?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    <Form.Group controlId="formZip">
                        <Form.Label>{t("zipcode")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ZIP code"
                            {...register("zip", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.zip}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.zip?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* 
                    <Form.Group controlId="formTitle">
                        <Form.Label>{t("title")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Title"
                            defaultValue={formValues?.title}
                            {...register("title", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.title?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>{t("price")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Price"
                            {...register("price", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.price?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formOffer">
                        <Form.Label>{t("offer")}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Offer"
                            {...register("offer", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                                min: {
                                    value: 0,
                                    message: t(
                                        "value_must_be_greater_than_or_equal_to_0"
                                    ),
                                },
                                max: {
                                    value: 100,
                                    message: t(
                                        "value_must_be_less_than_or_equal_to_100"
                                    ),
                                },
                            })}
                            isInvalid={!!errors.offer}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.offer?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formTown">
                        <Form.Label>{t("town")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="town"
                            {...register("town", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            isInvalid={!!errors.town}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.town?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>{t("description")}</Form.Label>

                        <ReactQuill
                            value={formValues.description}
                            onChange={descriptionChange}
                            modules={modules}
                            style={{ height: "200px", borderRadius: "10px" }}
                            maxLength={3000} // Set the maximum length to 3000 characters
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.description?.message}
                        </Form.Control.Feedback>
                    </Form.Group> */}

                    <div style={{ marginTop: "10px" }}>
                        <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                            style={{ marginLeft: "10px" }}
                        >
                            {laguages &&
                                laguages?.map((item) => (
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className={`nav-link ${
                                                currentLangCode === item.code &&
                                                "active"
                                            }`}
                                            id={`${item.code}-tab`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#${item.code}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`${item.code}`}
                                            aria-selected="true"
                                            onClick={() => {
                                                setCurrentLangCode(item?.code);
                                            }}
                                        >
                                            <img
                                                src={`/${item?.flagImage}`}
                                                alt=""
                                            />
                                        </button>
                                    </li>
                                ))}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {laguages &&
                                laguages?.map((item) => (
                                    <div
                                        className={`tab-pane fade ${
                                            currentLangCode === item.code &&
                                            "active show"
                                        } `}
                                        id={`${item.code}`}
                                        role="tabpanel"
                                        aria-labelledby={`${item.code}-tab`}
                                    >
                                        <div>
                                            <Form.Group controlId="formTitle">
                                                <Form.Label>
                                                    {t("title")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Title"
                                                    {...register(
                                                        `${item.code}.title`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.title
                                                    }
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.title?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formPrice">
                                                <Form.Label>
                                                    {t("price")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Price"
                                                    {...register(
                                                        `${item.code}.price`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.price
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.price?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formOffer">
                                                <Form.Label>
                                                    {t("offer")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Offer"
                                                    {...register(
                                                        `${item.code}.offer`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                            min: {
                                                                value: 0,
                                                                message: t(
                                                                    "value_must_be_greater_than_or_equal_to_0"
                                                                ),
                                                            },
                                                            max: {
                                                                value: 100,
                                                                message: t(
                                                                    "value_must_be_less_than_or_equal_to_100"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.offer
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.offer?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formTown">
                                                <Form.Label>
                                                    {t("town")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Town"
                                                    {...register(
                                                        `${item.code}.town`,
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: t(
                                                                    "this_field_is_required"
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                    isInvalid={
                                                        !!errors[`${item.code}`]
                                                            ?.town
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item.code}`]
                                                            ?.town?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formDescription">
                                                <Form.Label>
                                                    {t("description")}
                                                </Form.Label>
                                                <ReactQuill
                                                    value={
                                                        formValues[item?.code]
                                                            ?.description
                                                    }
                                                    onChange={descriptionChange}
                                                    modules={modules}
                                                    style={{
                                                        height: "200px",
                                                        borderRadius: "10px",
                                                    }}
                                                    maxLength={3000} // Set the maximum length to 3000 characters
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors[`${item?.code}`]
                                                            ?.description
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div
                        className="form modal-input-form "
                        style={{ marginTop: "60px" }}
                    >
                        <p style={{ marginBottom: "5px" }}>
                            {t("thumbnailImage")}
                        </p>
                        {!!thumbnailImageEdit ? (
                            <ImageComponent
                                src={thumbnailImageEdit}
                                type="thumbnail"
                                index={0}
                            />
                        ) : (
                            <Upload
                                listType="picture-card"
                                fileList={thumbnailImageList}
                                onChange={handleChangeThumbNail}
                                name="images"
                                customRequest={({ onSuccess }) =>
                                    onSuccess("success")
                                }
                                // previewFile={false}
                                showPreviewIcon={false}
                                multiple={false}
                                showUploadList={{ showPreviewIcon: false }}
                            >
                                {thumbnailImageList.length >= 1
                                    ? null
                                    : uploadButton}
                            </Upload>
                        )}
                    </div>
                    {addMoreImageStatus && (
                        <div
                            className="form modal-input-form"
                            style={{ marginTop: "12px" }}
                        >
                            <p style={{ marginBottom: "5px" }}>
                                {t("attachImages")}
                            </p>
                            {addMoreImagesEditList.length != 0 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            padding: 0,
                                        }}
                                    >
                                        {" "}
                                        {addMoreImagesEditList.map((item) => {
                                            return (
                                                <ImageComponent
                                                    src={item?.image}
                                                    type="addMore"
                                                    index={item?.id}
                                                />
                                            );
                                        })}
                                    </div>
                                    {addMoreImagesEditList.length < 8 && (
                                        <Upload
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={handleChangeFile}
                                            name="images"
                                            customRequest={({ onSuccess }) =>
                                                onSuccess("success")
                                            }
                                            // previewFile={false}
                                            showPreviewIcon={false}
                                            multiple={true}
                                            showUploadList={{
                                                showPreviewIcon: false,
                                            }}
                                        >
                                            {fileList.length >=
                                            8 - addMoreImagesEditList.length
                                                ? null
                                                : uploadButton}
                                        </Upload>
                                    )}
                                </div>
                            ) : (
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChangeFile}
                                    name="images"
                                    customRequest={({ onSuccess }) =>
                                        onSuccess("success")
                                    }
                                    // previewFile={false}
                                    showPreviewIcon={false}
                                    multiple={true}
                                    showUploadList={{ showPreviewIcon: false }}
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            )}
                        </div>
                    )}

                    <div style={{ marginTop: "15px" }}>
                        <SubmitButton
                            isSubmitting={false}
                            click={handleSubmit}
                            text="submit"
                            className={"btn btn-primary submit_btn"}
                            // isLoading={transferMutation.isLoading}
                            isLoading={AddAdvertMutation.isLoading}
                        />
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditAdvertModal;
