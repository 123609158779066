import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ReplicaRegisterModal = ({ show, handleClose, data }) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t("termsAndCondition")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* {data} */}
                <div
                    contentEditable="false"
                    dangerouslySetInnerHTML={{ __html: data }}
                ></div>
            </Modal.Body>
        </Modal>
    );
};

export default ReplicaRegisterModal;
