import React, { useEffect, useState } from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import PieChart from "../../components/Dashboard/PieChart";
// import Tour from 'reactour'
import { disableIsTourOpen } from "../../store/reducers/userReducer";
import ProfileLayout from "../profile/MainProfile";
import { useNavigate } from "react-router";

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"));
    const joiningChartData = useSelector(
        (state) => state.dashboard?.dashboardOne
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );
    const userType = useSelector((state) => state?.user?.userType);

    const dashboard = ApiHook.CallDashboardTiles();
    const dashboardDetails = ApiHook.CallDashboardDetails();
    const navigate = useNavigate();

    useEffect(() => {
        if (userType === "advertiser") {
            navigate("/adverts");
        }
    }, [userType]);

    return (
        <>
            <div data-tut="dashboard" className="page_head_top">
                {t("dashboard")}
            </div>
            <div className="center_content_head">
                <span>
                    {t("welcome")} {user?.fullName}{" "}
                </span>
            </div>
            {userType === "member" && (
                <QuickBalance
                    tiles={dashboard?.data}
                    currency={userSelectedCurrency}
                    conversionFactor={conversionFactor}
                />
            )}

            {userType !== "advertiser" && (
                <div className="joining_Teammbr_section">
                    <div className="row">
                        <JoiningChart charts={joiningChartData} />
                        {userType === "member" && (
                            <TeamMembers
                                members={dashboardDetails?.data?.newMembers}
                            />
                        )}
                        {userType === "customer" && (
                            <PieChart
                                chartData={{
                                    toolTipData:
                                        dashboardDetails?.data?.newCustomers
                                            ?.data,
                                    toolTipCount:
                                        dashboardDetails?.data?.newCustomers
                                            ?.count,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {userType === "member" && (
                <div className="team_members_earning_section">
                    <div className="row">
                        <TeamMembersEarningSection
                            topEarners={dashboardDetails?.data?.topEarners}
                            currency={userSelectedCurrency}
                            conversionFactor={conversionFactor}
                        />
                        {!!moduleStatus?.rank_status && (
                            <RankComponent
                                ranks={dashboardDetails?.data?.ranks}
                                currentRank={
                                    dashboardDetails?.data?.currentRank
                                }
                            />
                        )}
                        <EarningsExpenses
                            earnings={dashboardDetails?.data?.earnings}
                            currency={userSelectedCurrency}
                            conversionFactor={conversionFactor}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
